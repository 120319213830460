<template>
  <div class="home">
    <div id="wrapper">
      <Sidebar :role="userInfo.role"/>
      <div id="content-wrapper" class="d-flex flex-column">
        <div id="content">
          <Navbar :name="userInfo.name"/>
          <div class="container-fluid">
            <div class="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 class="h3 mb-0 text-gray-800"></h1>
            </div>
            <div class="row">
             
              <div class="col-xl-3 col-md-6 mb-4">
                  <div class="card border-left-info shadow h-100 py-2">
                      <div class="card-body">
                          <div class="row no-gutters align-items-center">
                              <div class="col mr-2">
                                  <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                      Jumlah Data</div>
                                  <div class="h5 mb-0 font-weight-bold text-gray-800">{{rows}}</div>
                              </div>
                              <div class="col-auto">
                                  <i class="fas fa-clipboard-list fa-2x text-gray-300"></i>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>

              <!-- <div class="col-xl-3 col-md-6 mb-4">
                  <div class="card border-left-info shadow h-100 py-2">
                      <div class="card-body">
                          <div class="row no-gutters align-items-center">
                              <div class="col mr-2">
                                    <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                      Suhu
                                    </div>
                                    <div class="h5 mb-0 font-weight-bold text-gray-800">{{lastItem.suhu}}</div>
                              </div>
                              <div class="col-auto">
                                  <i class="fas fa-fire fa-2x text-gray-300"></i>
                              </div>
                          </div>
                      </div>
                  </div>
              </div> -->

              
            </div>

            <div class="card shadow mb-4">
                <div class="card-header py-3">
                    <div class="card-body">
                        <div class="row" style="padding-bottom:20px;">
                            <div class="col-6">
                                <h5>Data Sensor</h5>
                            </div>
                        </div>
                        <div class="row">
                            <b-table
                                bordered
                                striped
                                hover
                                show-empty
                                empty-text="There are no records to show"
                                index :items="AllData"
                                :fields="fields"
                                :per-page="perPage"
                                :current-page="currentPage"
                                responsive
                            >
                                <template #cell(index)="data">
                                    {{ data.index + 1 }}
                                </template>
                                <template #cell(owner)="data">
                                    {{ data.item.owner.name }}
                                </template>
                                <template #cell(owneremail)="data">
                                    {{ data.item.owner.email }}
                                </template>
                                <template #cell(action)="data">
                                    <button @click="deleteArticle(data.item._id)" class="btn btn-danger btn-circle btn-sm">
                                        <i class="fas fa-trash"></i></button>
                                </template>
                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <b-pagination
                                    v-model="currentPage"
                                    :total-rows="rows"
                                    :per-page="perPage"
                                >
                                </b-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <p>Copyright &copy; 2022 Nanosense Instrument Indonesia. All rights reserved.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Sidebar from '@/components/Sidebar.vue'
import Navbar from '@/components/Navbar.vue'
import axios from 'axios'
import Swal from 'sweetalert2'
// import '@netcz/vue-pikaday'
// import moment from 'moment'

export default {
  name: 'Dashboard',
  components: {
    Navbar,
    Sidebar
  },
  props: ['url'],
  data: () => {
      return {
          userInfo: {},
          AllData: [],
          perPage: 10,
          currentPage: 1,
          fields: [
                {
                    key: 'index',
                    label: 'No'
                },
                {
                    key: 'date',
                    label: 'Date',
                    // formatter: (value) => {
                    //     return new Date(value).toLocaleString("id-ID", {timeZone: "Asia/Jakarta"})
                    // },
                    sortable: true
                },
                {
                    key: 'time',
                    label: 'Time',
                    sortable: true
                },
                {
                    key: 'sensor',
                    label: 'Sensor',
                    sortable: true
                },
                {
                    key: 'suhu',
                    label: 'Suhu',
                    sortable: true
                },
                {
                    key: 'tekanan',
                    label: 'Tekanan',
                    sortable: true
                },
                {
                    key: 'kelembapan',
                    label: 'Kelembapan',
                    sortable: true
                },
                {
                    key: 'owner',
                    label: 'Owner',
                    sortable: true
                },
                {
                    key: 'owneremail',
                    label: 'Owner Email',
                    sortable: true
                },
                {
                    key: 'action',
                    label: 'Action'
                },
            ]
      }
  },
  methods : {
      getUserInfo() {
        axios.get(`${this.url}/users/${localStorage.getItem('user_id')}`)
        .then((response) => {
            let userInfo = response.data.data
            this.userInfo = userInfo
        })
        .catch((error) => {
            console.log(error.message)
        })
      },
      getData() {
        let config = {
            method: 'GET',
            headers: {'auth-tok': localStorage.getItem('token_monitor')},
            baseURL: `${this.url}/sensors/all`
        }
        axios(config)
        .then((response) => {
            this.AllData = response.data.data
        })
        .catch((error) => {
            console.log(error.message)
        })
      },
      getDetail(id) {
          this.$router.push(`/detail/${id}`)
          this.getData()
      },
      deleteArticle(id) {
        let config = {
            method: 'DELETE',
            headers: {'auth-tok': localStorage.getItem('token_monitor')},
            baseURL: `${this.url}/sensors/${id}`,
            data: id
        }
        axios(config)
            .then(() =>{
                Swal.fire('Article deleted Successfully', '', 'success')
            })
            .catch((error) => {
                Swal.fire('Something went wrong!', '', 'error')
                console.log(error.message)
            })
      },

    
  },
  created() {
      if (!localStorage.getItem("token_monitor")) {
          this.$router.push('/')
      }
      else {
        this.getData()
        this.getUserInfo()
      }
  },
  mounted() {
      if (localStorage.getItem('reloaded')) {
          localStorage.removeItem('reloaded');
      }
      else {
          localStorage.setItem('reloaded', '1');
          location.reload();
       }
  },
  computed: {
        rows() {
            return this.AllData.length
        },

        lastItem() {
            return this.AllData.slice(-1)[0]
        }
    }
}
</script>
