<template>
  <div class="home">
      <div id="wrapper">
          <Sidebar :role="userInfo.role"/>
          <div id="content-wrapper" class="d-flex flex-column">
              <div id="content">
                  <Navbar :name="userInfo.name"/>
                  <div class="container-fluid">
                      <div class="d-sm-flex align-items-center justify-content-between mb-4">
                          <h1 class="h3 mb-0 text-gray-800"></h1>
                      </div>
                      <div class="row">

                            <div class="col-xl-3 col-md-6 mb-4">
                                <div class="card border-left-info shadow h-100 py-2">
                                    <div class="card-body">
                                        <div class="row no-gutters align-items-center">
                                            <div class="col mr-2">
                                                <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                                    Jumlah Data</div>
                                                <div class="h5 mb-0 font-weight-bold text-gray-800">{{rows}}</div>
                                            </div>
                                            <div class="col-auto">
                                                <i class="fas fa-clipboard-list fa-2x text-gray-300"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- <div class="col-xl-3 col-md-6 mb-4">
                                <div class="card border-left-info shadow h-100 py-2">
                                    <div class="card-body">
                                        <div class="row no-gutters align-items-center">
                                            <div class="col mr-2">
                                                    <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                                        Suhu
                                                    </div>
                                                    <div class="h5 mb-0 font-weight-bold text-gray-800">{{lastItem.suhu}}</div>
                                            </div>
                                            <div class="col-auto">
                                                <i class="fas fa-fire fa-2x text-gray-300"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                            
                        </div>

                        <div class="col-lg-12">
                            <div class="card shadow mb-4">
                                <div class="card-header py-3">
                                    <div class="card-body">
                                        <div v-if="loaded" style="text-align:center;">
                                            <h5>Plot Line Temperature</h5>
                                        </div>
                                            <div class="row">
                                                <div class="col-md-12 mx-auto" style="text-align:center;">
                                                    <LineChart 
                                                        v-if="loaded"
                                                        :chart-data="datacollection8"
                                                        :height= "100"
                                                    />
                                                </div>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div> 

                        <div class="col-lg-12">
                            <div class="card shadow mb-4">
                                <div class="card-header py-3">
                                    <div class="card-body">
                                        <div v-if="loaded" style="text-align:center;">
                                            <h5>Plot Line Pressure</h5>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12 mx-auto" style="text-align:center;">
                                                <LineChart 
                                                    v-if="loaded"
                                                    :chart-data="datacollection16"
                                                    :height = "100"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12">
                            <div class="card shadow mb-4">
                                <div class="card-header py-3">
                                    <div class="card-body">
                                        <div v-if="loaded" style="text-align:center;">
                                            <h5>Plot Line Humidity</h5>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12 mx-auto" style="text-align:center;">
                                                <LineChart 
                                                    v-if="loaded"
                                                    :chart-data="datacollection24"
                                                    :height = "100"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <p>Copyright &copy; 2022 Nanosense Instrument Indonesia. All rights reserved.</p>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>
<script>
// @ is an alias to /src
import Sidebar from '@/components/Sidebar.vue'
import Navbar from '@/components/Navbar.vue'
import LineChart from '@/components/LineChart.vue'
import axios from 'axios'
// import '@netcz/vue-pikaday'
// import moment from 'moment'

export default {
  name: 'Detail',
  components: {
    Navbar,
    Sidebar,
    LineChart
  },
  props: ['url'],
  data: () => {
      return {
          userInfo: {},
          AllData: [],
          datacollection8: null,
          datacollection16: null,
          datacollection24: null,
          loaded: false,
          suhu7: [],
          tekanan7: [],
          kelembapan7: [],
          time: []

      }
      
  },
  methods : {
      getUserInfo() {
            axios.get(`${this.url}/users/${localStorage.getItem('user_id')}`)
            .then((response) => {
                let userInfo = response.data.data
                this.userInfo = userInfo
            })
            .catch((error) => {
                console.log(error.message)
            })
      },

      getData() {
            let config = {
                method: 'GET',
                headers: {'auth-tok': localStorage.getItem('token_monitor')},
                baseURL: `${this.url}/sensors/all`
            }
            axios(config)
            .then((response) => {
                this.AllData = response.data.data
            })
            .catch((error) => {
                console.log(error.message)
            })
        },

        fillDataTemperature8() {
          this.datacollection8 = {
                labels: this.time,
                
                datasets: [{
                    label: 'Sensor 8 Temp (°C)',
                    backgroundColor: 'rgba(0, 0, 255, 0.2)',
                    fill: false,
                    borderColor: 'lightred', 
                    pointBackgroundColor: 'red', 
                    borderWidth: 1, 
                    pointBorderColor: 'red',
                    responsive: true,
                    maintainAspectRatio: false,
                    data: this.suhu7
                },]
            }
            
        },

        fillDataPressure8() {
          this.datacollection16 = {
                labels: this.time,
                
                datasets: [{
                    label: 'Sensor 8 Pressure (Pa)',
                    backgroundColor: 'rgba(0, 0, 255, 0.2)',
                    fill: false,
                    borderColor: 'lightgreen', 
                    pointBackgroundColor: 'green', 
                    borderWidth: 1, 
                    pointBorderColor: 'green',
                    responsive: true,
                    maintainAspectRatio: false,
                    data: this.tekanan7
                },]
            }
            
        },

        fillDataHumidity8() {
          this.datacollection24 = {
                labels: this.time,
                
                datasets: [{
                    label: 'Sensor 8 Humidity (%)',
                    backgroundColor: 'rgba(0, 0, 255, 0.2)',
                    fill: false,
                    borderColor: 'lightblue', 
                    pointBackgroundColor: 'blue', 
                    borderWidth: 1, 
                    pointBorderColor: 'blue',
                    responsive: true,
                    maintainAspectRatio: false,
                    data: this.kelembapan7
                },]
            }
            
        },

        getSensTemperature8() {
                    let sens = this.AllData
                    let filtersuhu7 = sens.filter(function (sen) {
                        return sen.sensor === "7";
                        }).map(function (sen) {
                            return sen.suhu;
                        })
                    let filtersuhu7time = sens.filter(function (sen) {
                        return sen.sensor === "7";
                        }).map(function (sen) {
                            return sen.time;
                        })
                    let temp = []
                    let time = []
                    for(let i=filtersuhu7.length-10; i<filtersuhu7.length; i++) {
                        let t = Number(filtersuhu7[i])
                        temp.push(t)
                    }
                    for(let i=filtersuhu7time.length-10; i<filtersuhu7time.length; i++) {
                        let ti = filtersuhu7time[i]
                        time.push(ti)
                    }
                    this.suhu7 = temp
                    this.time = time
                    this.loaded = true
                    this.fillDataTemperature8()

        },

        getSensPressure8() {
                    let sens = this.AllData
                    let filtertekanan7 = sens.filter(function (sen) {
                        return sen.sensor === "7";
                        }).map(function (sen) {
                            return sen.tekanan;
                        })
                    let filtertekanan7time = sens.filter(function (sen) {
                        return sen.sensor === "7";
                        }).map(function (sen) {
                            return sen.time;
                        })
                    let press = []
                    let time = []
                    for(let i=filtertekanan7.length-10; i<filtertekanan7.length; i++) {
                        let p = Number(filtertekanan7[i])
                        press.push(p)
                    }
                    for(let i=filtertekanan7time.length-10; i<filtertekanan7time.length; i++) {
                        let ti = filtertekanan7time[i]
                        time.push(ti)
                    }
                    this.tekanan7 = press
                    this.time = time
                    this.loaded = true
                    this.fillDataPressure8()
        },

        getSensHumidity8() {
                    let sens = this.AllData
                    let filterkelembapan7 = sens.filter(function (sen) {
                        return sen.sensor === "7";
                        }).map(function (sen) {
                            return sen.kelembapan;
                        })
                    let filterkelembapan7time = sens.filter(function (sen) {
                        return sen.sensor === "7";
                        }).map(function (sen) {
                            return sen.time;
                        })
                    let hum = []
                    let time = []
                    for(let i=filterkelembapan7.length-10; i<filterkelembapan7.length; i++) {
                        let h = Number(filterkelembapan7[i])
                        hum.push(h)
                    }
                    for(let i=filterkelembapan7time.length-10; i<filterkelembapan7time.length; i++) {
                        let ti = filterkelembapan7time[i]
                        time.push(ti)
                    }
                    this.kelembapan7 = hum
                    this.time = time
                    this.loaded = true
                    this.fillDataHumidity8()
        },


  },
  created() {
      if (!localStorage.getItem("token_monitor")) {
          this.$router.push('/')
      }
      else {
            this.getUserInfo()
            this.getData()
            this.fillDataTemperature8()
            this.fillDataPressure8()
            this.fillDataHumidity8()
            this.loaded = false
            this.getSensTemperature8()
            this.getSensPressure8()
            this.getSensHumidity8()  
      }
  },
  mounted() {
      if (localStorage.getItem('reloaded')) {
          localStorage.removeItem('reloaded');
      }
      else {
          localStorage.setItem('reloaded', '1');
          location.reload();
       }

       setInterval( () => {
            this.getData()
            this.fillDataTemperature8()
            this.fillDataPressure8()
            this.fillDataHumidity8()
            this.getSensTemperature8()
            this.getSensPressure8()
            this.getSensHumidity8()
        }, 2500)
  },

  computed: {
        rows() {
            return this.AllData.length
        },

        lastItem() {
            return this.AllData.slice(-1)[0]
        }
    }
}
</script>