<template>
  <div class="home">
      <div id="wrapper">
          <Sidebar :role="userInfo.role"/>
          <div id="content-wrapper" class="d-flex flex-column">
              <div id="content">
                  <Navbar :name="userInfo.name"/>
                  <div class="container-fluid">
                      <div class="d-sm-flex align-items-center justify-content-between mb-4">
                          <h1 class="h3 mb-0 text-gray-800"></h1>
                      </div>
                      <div class="row">

                            <div class="col-xl-3 col-md-6 mb-4">
                                <div class="card border-left-info shadow h-100 py-2">
                                    <div class="card-body">
                                        <div class="row no-gutters align-items-center">
                                            <div class="col mr-2">
                                                <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                                    Jumlah Data</div>
                                                <div class="h5 mb-0 font-weight-bold text-gray-800">{{rows}}</div>
                                            </div>
                                            <div class="col-auto">
                                                <i class="fas fa-clipboard-list fa-2x text-gray-300"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- <div class="col-xl-3 col-md-6 mb-4">
                                <div class="card border-left-info shadow h-100 py-2">
                                    <div class="card-body">
                                        <div class="row no-gutters align-items-center">
                                            <div class="col mr-2">
                                                    <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                                        Suhu
                                                    </div>
                                                    <div class="h5 mb-0 font-weight-bold text-gray-800">{{lastItem.suhu}}</div>
                                            </div>
                                            <div class="col-auto">
                                                <i class="fas fa-fire fa-2x text-gray-300"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                            
                        </div>

                        <div class="col-lg-12">
                            <div class="card shadow mb-4">
                                <div class="card-header py-3">
                                    <div class="card-body">
                                        <div v-if="loaded" style="text-align:center;">
                                            <h5>Plot Line Temperature</h5>
                                        </div>
                                            <div class="row">
                                                <div class="col-md-12 mx-auto" style="text-align:center;">
                                                    <LineChart 
                                                        v-if="loaded"
                                                        :chart-data="datacollection3"
                                                        :height = "100"
                                                    />
                                                </div>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div> 

                        <div class="col-lg-12">
                            <div class="card shadow mb-4">
                                <div class="card-header py-3">
                                    <div class="card-body">
                                        <div v-if="loaded" style="text-align:center;">
                                            <h5>Plot Line Pressure</h5>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12 mx-auto" style="text-align:center;">
                                                <LineChart 
                                                    v-if="loaded"
                                                    :chart-data="datacollection11"
                                                    :height = "100"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12">
                            <div class="card shadow mb-4">
                                <div class="card-header py-3">
                                    <div class="card-body">
                                        <div v-if="loaded" style="text-align:center;">
                                            <h5>Plot Line Humidity</h5>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12 mx-auto" style="text-align:center;">
                                                <LineChart 
                                                    v-if="loaded"
                                                    :chart-data="datacollection19"
                                                    :height = "100"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <p>Copyright &copy; 2022 Nanosense Instrument Indonesia. All rights reserved.</p>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>
<script>
// @ is an alias to /src
import Sidebar from '@/components/Sidebar.vue'
import Navbar from '@/components/Navbar.vue'
import LineChart from '@/components/LineChart.vue'
import axios from 'axios'
// import '@netcz/vue-pikaday'
// import moment from 'moment'

export default {
  name: 'Detail',
  components: {
    Navbar,
    Sidebar,
    LineChart
  },
  props: ['url'],
  data: () => {
      return {
          userInfo: {},
          AllData: [],
          datacollection3: null,
          datacollection11: null,
          datacollection19: null,
          loaded: false,
          suhu2: [],
          tekanan2: [],
          kelembapan2: [],
          time: []

      }
      
  },
  methods : {
      getUserInfo() {
            axios.get(`${this.url}/users/${localStorage.getItem('user_id')}`)
            .then((response) => {
                let userInfo = response.data.data
                this.userInfo = userInfo
            })
            .catch((error) => {
                console.log(error.message)
            })
      },

      getData() {
            let config = {
                method: 'GET',
                headers: {'auth-tok': localStorage.getItem('token_monitor')},
                baseURL: `${this.url}/sensors/all`
            }
            axios(config)
            .then((response) => {
                this.AllData = response.data.data
            })
            .catch((error) => {
                console.log(error.message)
            })
        },

        fillDataTemperature3() {
          this.datacollection3 = {
                labels: this.time,
                
                datasets: [{
                    label: 'Sensor 3 Temp (°C)',
                    backgroundColor: 'rgba(0, 0, 255, 0.2)',
                    fill: false,
                    borderColor: 'lightred', 
                    pointBackgroundColor: 'red', 
                    borderWidth: 1, 
                    pointBorderColor: 'red',
                    responsive: true,
                    maintainAspectRatio: false,
                    data: this.suhu2
                },]
            }
            
        },

        fillDataPressure3() {
          this.datacollection11 = {
                labels: this.time,
                
                datasets: [{
                    label: 'Sensor 3 Pressure (Pa)',
                    backgroundColor: 'rgba(0, 0, 255, 0.2)',
                    fill: false,
                    borderColor: 'lightgreen', 
                    pointBackgroundColor: 'green', 
                    borderWidth: 1, 
                    pointBorderColor: 'green',
                    responsive: true,
                    maintainAspectRatio: false,
                    data: this.tekanan2
                },]
            }
            
        },

        fillDataHumidity3() {
          this.datacollection19 = {
                labels: this.time,
                
                datasets: [{
                    label: 'Sensor 3 Humidity (%)',
                    backgroundColor: 'rgba(0, 0, 255, 0.2)',
                    fill: false,
                    borderColor: 'lightblue', 
                    pointBackgroundColor: 'blue', 
                    borderWidth: 1, 
                    pointBorderColor: 'blue',
                    responsive: true,
                    maintainAspectRatio: false,
                    data: this.kelembapan2
                },]
            }
            
        },

        getSensTemperature3() {
                    let sens = this.AllData
                    let filtersuhu2 = sens.filter(function (sen) {
                        return sen.sensor === "2";
                        }).map(function (sen) {
                            return sen.suhu;
                        })
                    let filtersuhu2time = sens.filter(function (sen) {
                        return sen.sensor === "2";
                        }).map(function (sen) {
                            return sen.time;
                        })
                    let temp = []
                    let time = []
                    for(let i=filtersuhu2.length-10; i<filtersuhu2.length; i++) {
                        let t = Number(filtersuhu2[i])
                        temp.push(t)
                    }
                    for(let i=filtersuhu2time.length-10; i<filtersuhu2time.length; i++) {
                        let ti = filtersuhu2time[i]
                        time.push(ti)
                    }
                    this.suhu2 = temp
                    this.time = time
                    this.loaded = true
                    this.fillDataTemperature3()

        },

        getSensPressure3() {
                    let sens = this.AllData
                    let filtertekanan2 = sens.filter(function (sen) {
                        return sen.sensor === "2";
                        }).map(function (sen) {
                            return sen.tekanan;
                        })
                    let filtertekanan2time = sens.filter(function (sen) {
                        return sen.sensor === "2";
                        }).map(function (sen) {
                            return sen.time;
                        })
                    let press = []
                    let time = []
                    for(let i=filtertekanan2.length-10; i<filtertekanan2.length; i++) {
                        let p = Number(filtertekanan2[i])
                        press.push(p)
                    }
                    for(let i=filtertekanan2time.length-10; i<filtertekanan2time.length; i++) {
                        let ti = filtertekanan2time[i]
                        time.push(ti)
                    }
                    this.tekanan2 = press
                    this.time = time
                    this.loaded = true
                    this.fillDataPressure3()
        },

        getSensHumidity3() {
                    let sens = this.AllData
                    let filterkelembapan2 = sens.filter(function (sen) {
                        return sen.sensor === "2";
                        }).map(function (sen) {
                            return sen.kelembapan;
                        })
                    let filterkelembapan2time = sens.filter(function (sen) {
                        return sen.sensor === "2";
                        }).map(function (sen) {
                            return sen.time;
                        })
                    let hum = []
                    let time = []
                    for(let i=filterkelembapan2.length-10; i<filterkelembapan2.length; i++) {
                        let h = Number(filterkelembapan2[i])
                        hum.push(h)
                    }
                    for(let i=filterkelembapan2time.length-10; i<filterkelembapan2time.length; i++) {
                        let ti = filterkelembapan2time[i]
                        time.push(ti)
                    }
                    this.kelembapan2 = hum
                    this.time = time
                    this.loaded = true
                    this.fillDataHumidity3()
        },

  },
  created() {
      if (!localStorage.getItem("token_monitor")) {
          this.$router.push('/')
      }
      else {
            this.getUserInfo()
            this.getData()
            this.fillDataTemperature3()
            this.fillDataPressure3()
            this.fillDataHumidity3()
            this.loaded = false
            this.getSensTemperature3()
            this.getSensPressure3()
            this.getSensHumidity3()
      }
  },
  mounted() {
      if (localStorage.getItem('reloaded')) {
          localStorage.removeItem('reloaded');
      }
      else {
          localStorage.setItem('reloaded', '1');
          location.reload();
       }

       setInterval( () => {
            this.getData()
            this.fillDataTemperature3()
            this.fillDataPressure3()
            this.fillDataHumidity3()
            this.getSensTemperature3()
            this.getSensPressure3()
            this.getSensHumidity3()
        }, 2500)
  },

  computed: {
        rows() {
            return this.AllData.length
        },

        lastItem() {
            return this.AllData.slice(-1)[0]
        }
    }
}
</script>