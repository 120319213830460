<template>
    <ul class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">
        <!-- Sidebar - Brand -->
        <a class="sidebar-brand d-flex align-items-center justify-content-center">
            <div class="sidebar-brand-icon">
                <img class="sidebar-brand-icon" src="/img/Logo-Nanosense-Crop-White.png" width="70">
            </div>
            <div class="sidebar-brand-text mx-3">Sample Dashboard</div>
        </a>

        <!-- Divider -->
        <hr class="sidebar-divider my-0">

        <!-- Nav Item - Dashboard -->
        <li class="nav-item">
            <router-link to="/dashboard" class="nav-link">
                <i class="fas fa-fw fa-tachometer-alt"></i>
                <span>Dashboard</span></router-link>
        </li>

        <!-- Divider -->
        <hr class="sidebar-divider my-0">

        <!-- <li class="nav-item">
            <router-link to="/detail" class="nav-link">
                <i class="fas fa-fw fa-newspaper"></i>
                <span>Detail</span></router-link>
        </li> -->

        <li class="nav-item">
            <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseUtilities"
                aria-expanded="true" aria-controls="collapseUtilities">
                <i class="fas fa-fw fa-newspaper"></i>
                <span>Detail</span>
            </a>
            <div id="collapseUtilities" class="collapse" aria-labelledby="headingUtilities"
                    data-parent="#accordionSidebar">
                    <div class="bg-white py-2 collapse-inner rounded">
                        <h6 class="collapse-header">Sensors:</h6>
                        <router-link to="/detail" class="collapse-item">All</router-link>
                        <router-link to="/sensor1" class="collapse-item">Sensor 1</router-link>
                        <router-link to="/sensor2" class="collapse-item">Sensor 2</router-link>
                        <router-link to="/sensor3" class="collapse-item">Sensor 3</router-link>
                        <router-link to="/sensor4" class="collapse-item">Sensor 4</router-link>
                        <router-link to="/sensor5" class="collapse-item">Sensor 5</router-link>
                        <router-link to="/sensor6" class="collapse-item">Sensor 6</router-link>
                        <router-link to="/sensor7" class="collapse-item">Sensor 7</router-link>
                        <router-link to="/sensor8" class="collapse-item">Sensor 8</router-link>
                        
                    </div>
            </div>
        </li>

        <!-- Divider -->
        <hr class="sidebar-divider my-0">

        <li class="nav-item">
            <router-link to="/history" class="nav-link">
                <i class="fas fa-fw fa-list"></i>
                <span>History</span></router-link>
        </li>

        <!-- Divider -->
        <hr class="sidebar-divider d-none d-md-block">

        <!-- Sidebar Toggler (Sidebar) -->
        <div class="text-center d-none d-md-inline">
            <button class="rounded-circle border-0" id="sidebarToggle"></button>
        </div>
        
    </ul>
</template>

<script>
export default {
  name: 'Sidebar',
}
</script>
