import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Dashboard from '../views/Dashboard.vue'
import Detail from '../views/Detail.vue'
import Sensor1 from '../views/Sensor1.vue'
import Sensor2 from '../views/Sensor2.vue'
import Sensor3 from '../views/Sensor3.vue'
import Sensor4 from '../views/Sensor4.vue'
import Sensor5 from '../views/Sensor5.vue'
import Sensor6 from '../views/Sensor6.vue'
import Sensor7 from '../views/Sensor7.vue'
import Sensor8 from '../views/Sensor8.vue'
import Profile from '../views/Profile.vue'
import NotFound from '../views/NotFound.vue'
import Signup from '../views/Signup.vue'
import History from '../views/History.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile
  },
  {
    path: '/detail',
    name: 'Detail',
    component: Detail
  },
  {
    path: '/sensor1',
    name: 'Sensor1',
    component: Sensor1
  },
  {
    path: '/sensor2',
    name: 'Sensor2',
    component: Sensor2
  },
  {
    path: '/sensor3',
    name: 'Sensor3',
    component: Sensor3
  },
  {
    path: '/sensor4',
    name: 'Sensor4',
    component: Sensor4
  },
  {
    path: '/sensor5',
    name: 'Sensor5',
    component: Sensor5
  },
  {
    path: '/sensor6',
    name: 'Sensor6',
    component: Sensor6
  },
  {
    path: '/sensor7',
    name: 'Sensor7',
    component: Sensor7
  },
  {
    path: '/sensor8',
    name: 'Sensor8',
    component: Sensor8
  },
  {
    path: '/history',
    name: 'History',
    component: History
  },
  {
    path: '/*',
    name: 'NotFound',
    component: NotFound
  },
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
