<template>
  <div class="home">
    <div id="wrapper">
      <Sidebar :role="userInfo.role"/>
      <div id="content-wrapper" class="d-flex flex-column">
        <div id="content">
          <Navbar :name="userInfo.name"/>
          <div class="container-fluid">
            <div class="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 class="h3 mb-0 text-gray-800">Edit Profile</h1>
            </div>
            <div class="card shadow mb-4">
                <div class="card-header py-3">
                    <div class="card-body">
                        <div>
                            <div>
                                <div class="form-group">
                                    <label for="exampleInputName">Name</label>
                                    <input type="text" v-model="name" class="form-control" id="exampleInputName" aria-describedby="nameHelp">
                                </div>
                                <div class="form-group">
                                    <label for="exampleInputEmail">Email</label>
                                    <input type="text" v-model="email" class="form-control" id="exampleInputEmail" aria-describedby="emailHelp">
                                </div>
                                <div class="form-group">
                                    <label for="exampleInputPassword">Password</label>
                                    <input type="password" v-model="password" class="form-control" id="exampleInputPassword" aria-describedby="passwordHelp">
                                </div>
                                <p>leave it blank if it doesn't change</p>
                                <div class="form-group">
                                    <label for="exampleInputRePassword">Retype Password</label>
                                    <input type="password" v-model="repassword" class="form-control" id="exampleInputRePassword" aria-describedby="rePasswordHelp">
                                </div>
                                <p>leave it blank if it doesn't change</p>
                            </div>
                            <br>
                            <div class="row" style="padding-bottom:50px;">
                                <button @click="editUser()" class="btn btn-primary" type="button" data-dismiss="modal">Save Profile</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <p>Copyright &copy; 2022 Nanosense Instrument Indonesia. All rights reserved.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Sidebar from '@/components/Sidebar.vue'
import Navbar from '@/components/Navbar.vue'
import axios from 'axios'
import Swal from 'sweetalert2'


export default {
  name: 'Profile',
  components: {
    Navbar,
    Sidebar
  },
  props: ['url'],
  data: () => {
      return {
          userInfo: {},
          name: "",
          email: "",
          password: "",
          repassword: "",
      }
  },
  methods : {
      getUserInfo() {
        axios.get(`${this.url}/users/${localStorage.getItem('user_id')}`)
        .then((response) => {
            let userInfo = response.data.data
            this.userInfo = userInfo
            this.name = userInfo.name
            this.email = userInfo.email
        })
        .catch((error) => {
            console.log(error.message)
        })
      },
      editUser() {
          let obj = {
              name: this.name,
              email: this.email
          }

          console.log(this.userInfo._id)

          if (this.password !== "") {
              if (this.password == this.repassword) {
                  obj.password = this.password
              }
              else {
                Swal.fire('Password and Retype Password does not match!', '', 'error')

              }
          }
          axios.patch(`${this.url}/users/${this.userInfo._id}`, obj)
            .then(() =>{
                Swal.fire('User Profile Saved Successfully', '', 'success')
            })
            .catch((error) => {
                Swal.fire('Something went wrong!', '', 'error')
                console.log(error.error)
            })
      }
  },
  mounted() {
      if (localStorage.getItem('reloaded')) {
          localStorage.removeItem('reloaded');
      }
      else {
          localStorage.setItem('reloaded', '1');
          location.reload();
      }
  },
  created() {
      if (!localStorage.getItem("token_monitor")) {
          this.$router.push('/')
      }
      else {
        this.getUserInfo()
      }
  }
}
</script>
