<template>
  <div class="home">
    <div id="wrapper">
      <Sidebar :role="userInfo.role"/>
      <div id="content-wrapper" class="d-flex flex-column">
        <div id="content">
          <Navbar :name="userInfo.name"/>
          <div class="container-fluid">
            <div class="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 class="h3 mb-0 text-gray-800"></h1>
            </div>
            <div class="row">
              
              <div class="col-xl-3 col-md-6 mb-4">
                  <div class="card border-left-info shadow h-100 py-2">
                      <div class="card-body">
                          <div class="row no-gutters align-items-center">
                              <div class="col mr-2">
                                  <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                      Jumlah Data</div>
                                  <div class="h5 mb-0 font-weight-bold text-gray-800">{{rows}}</div>
                              </div>
                              <div class="col-auto">
                                  <i class="fas fa-clipboard-list fa-2x text-gray-300"></i>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>

              
              <!-- <div class="col-xl-3 col-md-6 mb-4">
                  <div class="card border-left-info shadow h-100 py-2">
                      <div class="card-body">
                          <div class="row no-gutters align-items-center">
                              <div class="col mr-2">
                                    <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                      Suhu
                                    </div>
                                    <div class="h5 mb-0 font-weight-bold text-gray-800">{{lastItem.suhu}}</div>
                              </div>
                              <div class="col-auto">
                                  <i class="fas fa-fire fa-2x text-gray-300"></i>
                              </div>
                          </div>
                      </div>
                  </div>
              </div> -->

            </div>
            <div class="card shadow mb-4">
                <div class="card-header py-3">
                    <div class="card-body">
                        <div class="row" style="padding-bottom:20px;">
                            <div class="col-xl-3 col-md-6 mb-4">
                                <vue-pikaday placeholder="Choose a date..." v-model="dateFrom" class="form-control"/>
                            </div>
                            <div class="col-xl-3 col-md-6 mb-4">
                                <vue-pikaday placeholder="Choose a date..." v-model="dateTo" class="form-control"/>
                            </div>
                            <div class="col-xl-3 col-md-6 mb-4">
                                <button @click="getDataByDate" class="d-sm-inline-block btn btn-sm btn-primary shadow-sm form-control">Submit</button>
                            </div>
                            <div class="col-xl-3 col-md-6 mb-4">
                                <json-excel v-if="allDataSensor.length > 0" 
                                    :data="allDataSensor"
                                    :name= "data1"
                                    type="csv"
                                    class="d-sm-inline-block btn btn-sm btn-success shadow-sm form-control">
                                    <i class="fas fa-file-csv fa-sm text-white-50"></i>
                                    Export Data CSV
                                </json-excel>
                            </div>
                        </div>
                        <div class="row">
                            <b-table
                                bordered
                                striped
                                hover
                                show-empty
                                empty-text="There are no records to show"
                                index :items="allDataSensor"
                                :fields="fields"
                                :per-page="perPage"
                                :current-page="currentPage"
                                :sort-compare="mySortCompare"
                                responsive
                            >
                                <template #cell(index)="data">
                                    {{ data.index + 1 }}
                                </template>
                                <!-- <template #cell(owner)="data">
                                    {{ data.item.owner.name }}
                                </template> -->
                                <!-- <template #cell(time)="data">
                                    {{ data.item.time }}
                                </template> -->
                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <b-pagination
                                    v-model="currentPage"
                                    :total-rows="rows"
                                    :per-page="perPage"
                                >
                                </b-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12">
                            <div class="card shadow mb-4">
                                <div class="card-header py-3">
                                    <div class="card-body">
                                        <div v-if="loaded" style="text-align:center;">
                                            <h5>Plot Line Temperature</h5>
                                        </div>
                                            <div class="row">
                                                <div class="col-xl-4 col-md-6 mb-4" style="text-align:center;">
                                                    <LineChart 
                                                        v-if="loaded"
                                                        :chart-data="datacollection"
                                                    />
                                                </div>
                                                <div class="col-xl-4 col-md-6 mb-4" style="text-align:center;">
                                                    <LineChart 
                                                        v-if="loaded"
                                                        :chart-data="datacollection2"
                                                    />
                                                </div>
                                                <div class="col-xl-4 col-md-6 mb-4" style="text-align:center;">
                                                    <LineChart 
                                                        v-if="loaded"
                                                        :chart-data="datacollection3"
                                                    />
                                                </div>
                                                <div class="col-xl-4 col-md-6 mb-4" style="text-align:center;">
                                                    <LineChart 
                                                        v-if="loaded"
                                                        :chart-data="datacollection4"
                                                    />
                                                </div>
                                                <div class="col-xl-4 col-md-6 mb-4" style="text-align:center;">
                                                    <LineChart 
                                                        v-if="loaded"
                                                        :chart-data="datacollection5"
                                                    />
                                                </div>
                                                <div class="col-xl-4 col-md-6 mb-4" style="text-align:center;">
                                                    <LineChart 
                                                        v-if="loaded"
                                                        :chart-data="datacollection6"
                                                    />
                                                </div>
                                                <div class="col-xl-4 col-md-6 mb-4" style="text-align:center;">
                                                    <LineChart 
                                                        v-if="loaded"
                                                        :chart-data="datacollection7"
                                                    />
                                                </div>
                                                <div class="col-xl-4 col-md-6 mb-4" style="text-align:center;">
                                                    <LineChart 
                                                        v-if="loaded"
                                                        :chart-data="datacollection8"
                                                    />
                                                </div>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div> 

                        <div class="col-lg-12">
                            <div class="card shadow mb-4">
                                <div class="card-header py-3">
                                    <div class="card-body">
                                        <div v-if="loaded" style="text-align:center;">
                                            <h5>Plot Line Pressure</h5>
                                        </div>
                                        <div class="row">
                                            <div class="col-xl-4 col-md-6 mb-4" style="text-align:center;">
                                                <LineChart 
                                                    v-if="loaded"
                                                    :chart-data="datacollection9"
                                                />
                                            </div>
                                            <div class="col-xl-4 col-md-6 mb-4" style="text-align:center;">
                                                <LineChart 
                                                    v-if="loaded"
                                                    :chart-data="datacollection10"
                                                />
                                            </div>
                                            <div class="col-xl-4 col-md-6 mb-4" style="text-align:center;">
                                                <LineChart 
                                                    v-if="loaded"
                                                    :chart-data="datacollection11"
                                                />
                                            </div>
                                            <div class="col-xl-4 col-md-6 mb-4" style="text-align:center;">
                                                <LineChart 
                                                    v-if="loaded"
                                                    :chart-data="datacollection12"
                                                />
                                            </div>
                                            <div class="col-xl-4 col-md-6 mb-4" style="text-align:center;">
                                                <LineChart 
                                                    v-if="loaded"
                                                    :chart-data="datacollection13"
                                                />
                                            </div>
                                            <div class="col-xl-4 col-md-6 mb-4" style="text-align:center;">
                                                <LineChart 
                                                    v-if="loaded"
                                                    :chart-data="datacollection14"
                                                />
                                            </div>
                                            <div class="col-xl-4 col-md-6 mb-4" style="text-align:center;">
                                                <LineChart 
                                                    v-if="loaded"
                                                    :chart-data="datacollection15"
                                                />
                                            </div>
                                            <div class="col-xl-4 col-md-6 mb-4" style="text-align:center;">
                                                <LineChart 
                                                    v-if="loaded"
                                                    :chart-data="datacollection16"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12">
                            <div class="card shadow mb-4">
                                <div class="card-header py-3">
                                    <div class="card-body">
                                        <div v-if="loaded" style="text-align:center;">
                                            <h5>Plot Line Humidity</h5>
                                        </div>
                                        <div class="row">
                                            <div class="col-xl-4 col-md-6 mb-4" style="text-align:center;">
                                                <LineChart 
                                                    v-if="loaded"
                                                    :chart-data="datacollection17"
                                                />
                                            </div>
                                            <div class="col-xl-4 col-md-6 mb-4" style="text-align:center;">
                                                <LineChart 
                                                    v-if="loaded"
                                                    :chart-data="datacollection18"
                                                />
                                            </div>
                                            <div class="col-xl-4 col-md-6 mb-4" style="text-align:center;">
                                                <LineChart 
                                                    v-if="loaded"
                                                    :chart-data="datacollection19"
                                                />
                                            </div>
                                            <div class="col-xl-4 col-md-6 mb-4" style="text-align:center;">
                                                <LineChart 
                                                    v-if="loaded"
                                                    :chart-data="datacollection20"
                                                />
                                            </div>
                                            <div class="col-xl-4 col-md-6 mb-4" style="text-align:center;">
                                                <LineChart 
                                                    v-if="loaded"
                                                    :chart-data="datacollection21"
                                                />
                                            </div>
                                            <div class="col-xl-4 col-md-6 mb-4" style="text-align:center;">
                                                <LineChart 
                                                    v-if="loaded"
                                                    :chart-data="datacollection22"
                                                />
                                            </div>
                                            <div class="col-xl-4 col-md-6 mb-4" style="text-align:center;">
                                                <LineChart 
                                                    v-if="loaded"
                                                    :chart-data="datacollection23"
                                                />
                                            </div>
                                            <div class="col-xl-4 col-md-6 mb-4" style="text-align:center;">
                                                <LineChart 
                                                    v-if="loaded"
                                                    :chart-data="datacollection24"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


            <p>Copyright &copy; 2022 Nanosense Instrument Indonesia. All rights reserved.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import Sidebar from '@/components/Sidebar.vue'
import Navbar from '@/components/Navbar.vue'
import LineChart from '@/components/LineChart.vue'
import axios from 'axios'
import '@netcz/vue-pikaday'
import JsonExcel from "vue-json-excel";
import moment from 'moment'

export default {
  name: 'History',
  components: {
    Navbar,
    Sidebar,
    LineChart,
    JsonExcel
  },
  props: ['url'],
  data: () => {
      return {
          userInfo: {},
          allHistory: [],
          allDataSensor:[],
          dateFrom: null,
          dateTo: null,
          datacollection: null,
          datacollection2: null,
          datacollection3: null,
          datacollection4: null,
          datacollection5: null,
          datacollection6: null,
          datacollection7: null,
          datacollection8: null,
          datacollection9: null,
          datacollection10: null,
          datacollection11: null,
          datacollection12: null,
          datacollection13: null,
          datacollection14: null,
          datacollection15: null,
          datacollection16: null,
          datacollection17: null,
          datacollection18: null,
          datacollection19: null,
          datacollection20: null,
          datacollection21: null,
          datacollection22: null,
          datacollection23: null,
          datacollection24: null,
          loaded: false,
          suhu0: [],
          suhu1: [],
          suhu2: [],
          suhu3: [],
          suhu4: [],
          suhu5: [],
          suhu6: [],
          suhu7: [],
          tekanan0: [],
          tekanan1: [],
          tekanan2: [],
          tekanan3: [],
          tekanan4: [],
          tekanan5: [],
          tekanan6: [],
          tekanan7: [],
          kelembapan0: [],
          kelembapan1: [],
          kelembapan2: [],
          kelembapan3: [],
          kelembapan4: [],
          kelembapan5: [],
          kelembapan6: [],
          kelembapan7: [],
          time: [],
          perPage: 10,
          currentPage: 1,
          fields: [
                {
                    key: 'index',
                    label: 'No'
                },
                {
                    key: 'date',
                    label: 'Date',
                    // formatter: (value) => {
                    //     return new Date(value).toLocaleString("id-ID", {timeZone: "Asia/Jakarta"})
                    // },
                    sortable: true
                },
                {
                    key: 'sensor',
                    label: 'Sensor',
                    sortable: true
                },
                {
                    key: 'time',
                    label: 'Time',
                    sortable: true
                },
                {
                    key: 'suhu',
                    label: 'Suhu',
                    sortable: true
                },
                {
                    key: 'tekanan',
                    label: 'Tekanan',
                    sortable: true
                },
                {
                    key: 'kelembapan',
                    label: 'Kelembapan',
                    sortable: true
                },
            ]
      }
      
  },
  methods : {
      getUserInfo() {
            axios.get(`${this.url}/users/${localStorage.getItem('user_id')}`)
            .then((response) => {
                let userInfo = response.data.data
                this.userInfo = userInfo
            })
            .catch((error) => {
                console.log(error.message)
            })
      },

      getData() {
            let config = {
                method: 'GET',
                headers: {'auth-tok': localStorage.getItem('token_monitor')},
                baseURL: `${this.url}/sensors/all`
            }
            axios(config)
            .then((response) => {
                this.allHistory = response.data.data
            })
            .catch((error) => {
                console.log(error.message)
            })
        },

        fillDataTemperature() {
          this.datacollection = {
                labels: this.time,
                
                datasets: [{
                    label: 'Sensor 1 Temp (°C)',
                    backgroundColor: 'rgba(0, 0, 255, 0.2)',
                    fill: false,
                    borderColor: 'lightred', 
                    pointBackgroundColor: 'red', 
                    borderWidth: 1, 
                    pointBorderColor: 'red',
                    responsive: true,
                    maintainAspectRatio: false,
                    data: this.suhu0
                },]
            }
            
        },

        fillDataTemperature2() {
          this.datacollection2 = {
                labels: this.time,
                
                datasets: [{
                    label: 'Sensor 2 Temp (°C)',
                    backgroundColor: 'rgba(0, 0, 255, 0.2)',
                    fill: false,
                    borderColor: 'lightred', 
                    pointBackgroundColor: 'red', 
                    borderWidth: 1, 
                    pointBorderColor: 'red',
                    responsive: true,
                    maintainAspectRatio: false,
                    data: this.suhu1
                },]
            }
            
        },

        fillDataTemperature3() {
          this.datacollection3 = {
                labels: this.time,
                
                datasets: [{
                    label: 'Sensor 3 Temp (°C)',
                    backgroundColor: 'rgba(0, 0, 255, 0.2)',
                    fill: false,
                    borderColor: 'lightred', 
                    pointBackgroundColor: 'red', 
                    borderWidth: 1, 
                    pointBorderColor: 'red',
                    responsive: true,
                    maintainAspectRatio: false,
                    data: this.suhu2
                },]
            }
            
        },

        fillDataTemperature4() {
          this.datacollection4 = {
                labels: this.time,
                
                datasets: [{
                    label: 'Sensor 4 Temp (°C)',
                    backgroundColor: 'rgba(0, 0, 255, 0.2)',
                    fill: false,
                    borderColor: 'lightred', 
                    pointBackgroundColor: 'red', 
                    borderWidth: 1, 
                    pointBorderColor: 'red',
                    responsive: true,
                    maintainAspectRatio: false,
                    data: this.suhu3
                },]
            }
            
        },

        fillDataTemperature5() {
          this.datacollection5 = {
                labels: this.time,
                
                datasets: [{
                    label: 'Sensor 5 Temp (°C)',
                    backgroundColor: 'rgba(0, 0, 255, 0.2)',
                    fill: false,
                    borderColor: 'lightred', 
                    pointBackgroundColor: 'red', 
                    borderWidth: 1, 
                    pointBorderColor: 'red',
                    responsive: true,
                    maintainAspectRatio: false,
                    data: this.suhu4
                },]
            }
            
        },

        fillDataTemperature6() {
          this.datacollection6 = {
                labels: this.time,
                
                datasets: [{
                    label: 'Sensor 6 Temp (°C)',
                    backgroundColor: 'rgba(0, 0, 255, 0.2)',
                    fill: false,
                    borderColor: 'lightred', 
                    pointBackgroundColor: 'red', 
                    borderWidth: 1, 
                    pointBorderColor: 'red',
                    responsive: true,
                    maintainAspectRatio: false,
                    data: this.suhu5
                },]
            }
            
        },

        fillDataTemperature7() {
          this.datacollection7 = {
                labels: this.time,
                
                datasets: [{
                    label: 'Sensor 7 Temp (°C)',
                    backgroundColor: 'rgba(0, 0, 255, 0.2)',
                    fill: false,
                    borderColor: 'lightred', 
                    pointBackgroundColor: 'red', 
                    borderWidth: 1, 
                    pointBorderColor: 'red',
                    responsive: true,
                    maintainAspectRatio: false,
                    data: this.suhu6
                },]
            }
            
        },

        fillDataTemperature8() {
          this.datacollection8 = {
                labels: this.time,
                
                datasets: [{
                    label: 'Sensor 8 Temp (°C)',
                    backgroundColor: 'rgba(0, 0, 255, 0.2)',
                    fill: false,
                    borderColor: 'lightred', 
                    pointBackgroundColor: 'red', 
                    borderWidth: 1, 
                    pointBorderColor: 'red',
                    responsive: true,
                    maintainAspectRatio: false,
                    data: this.suhu7
                },]
            }
            
        },

        fillDataPressure() {
          this.datacollection9 = {
                labels: this.time,
                
                datasets: [{
                    label: 'Sensor 1 Pressure (Pa)',
                    backgroundColor: 'rgba(0, 0, 255, 0.2)',
                    fill: false,
                    borderColor: 'lightgreen', 
                    pointBackgroundColor: 'green', 
                    borderWidth: 1, 
                    pointBorderColor: 'green',
                    responsive: true,
                    maintainAspectRatio: false,
                    data: this.tekanan0
                },]
            }
            
        },

        fillDataPressure2() {
          this.datacollection10 = {
                labels: this.time,
                
                datasets: [{
                    label: 'Sensor 2 Pressure (Pa)',
                    backgroundColor: 'rgba(0, 0, 255, 0.2)',
                    fill: false,
                    borderColor: 'lightgreen', 
                    pointBackgroundColor: 'green', 
                    borderWidth: 1, 
                    pointBorderColor: 'green',
                    responsive: true,
                    maintainAspectRatio: false,
                    data: this.tekanan1
                },]
            }
            
        },

        fillDataPressure3() {
          this.datacollection11 = {
                labels: this.time,
                
                datasets: [{
                    label: 'Sensor 3 Pressure (Pa)',
                    backgroundColor: 'rgba(0, 0, 255, 0.2)',
                    fill: false,
                    borderColor: 'lightgreen', 
                    pointBackgroundColor: 'green', 
                    borderWidth: 1, 
                    pointBorderColor: 'green',
                    responsive: true,
                    maintainAspectRatio: false,
                    data: this.tekanan2
                },]
            }
            
        },

        fillDataPressure4() {
          this.datacollection12 = {
                labels: this.time,
                
                datasets: [{
                    label: 'Sensor 4 Pressure (Pa)',
                    backgroundColor: 'rgba(0, 0, 255, 0.2)',
                    fill: false,
                    borderColor: 'lightgreen', 
                    pointBackgroundColor: 'green', 
                    borderWidth: 1, 
                    pointBorderColor: 'green',
                    responsive: true,
                    maintainAspectRatio: false,
                    data: this.tekanan3
                },]
            }
            
        },

        fillDataPressure5() {
          this.datacollection13 = {
                labels: this.time,
                
                datasets: [{
                    label: 'Sensor 5 Pressure (Pa)',
                    backgroundColor: 'rgba(0, 0, 255, 0.2)',
                    fill: false,
                    borderColor: 'lightgreen', 
                    pointBackgroundColor: 'green', 
                    borderWidth: 1, 
                    pointBorderColor: 'green',
                    responsive: true,
                    maintainAspectRatio: false,
                    data: this.tekanan4
                },]
            }
            
        },

        fillDataPressure6() {
          this.datacollection14 = {
                labels: this.time,
                
                datasets: [{
                    label: 'Sensor 6 Pressure (Pa)',
                    backgroundColor: 'rgba(0, 0, 255, 0.2)',
                    fill: false,
                    borderColor: 'lightgreen', 
                    pointBackgroundColor: 'green', 
                    borderWidth: 1, 
                    pointBorderColor: 'green',
                    responsive: true,
                    maintainAspectRatio: false,
                    data: this.tekanan5
                },]
            }
            
        },

        fillDataPressure7() {
          this.datacollection15 = {
                labels: this.time,
                
                datasets: [{
                    label: 'Sensor 7 Pressure (Pa)',
                    backgroundColor: 'rgba(0, 0, 255, 0.2)',
                    fill: false,
                    borderColor: 'lightgreen', 
                    pointBackgroundColor: 'green', 
                    borderWidth: 1, 
                    pointBorderColor: 'green',
                    responsive: true,
                    maintainAspectRatio: false,
                    data: this.tekanan6
                },]
            }
            
        },

        fillDataPressure8() {
          this.datacollection16 = {
                labels: this.time,
                
                datasets: [{
                    label: 'Sensor 8 Pressure (Pa)',
                    backgroundColor: 'rgba(0, 0, 255, 0.2)',
                    fill: false,
                    borderColor: 'lightgreen', 
                    pointBackgroundColor: 'green', 
                    borderWidth: 1, 
                    pointBorderColor: 'green',
                    responsive: true,
                    maintainAspectRatio: false,
                    data: this.tekanan7
                },]
            }
            
        },

        fillDataHumidity() {
          this.datacollection17 = {
                labels: this.time,
                
                datasets: [{
                    label: 'Sensor 1 Humidity (%)',
                    backgroundColor: 'rgba(0, 0, 255, 0.2)',
                    fill: false,
                    borderColor: 'lightblue', 
                    pointBackgroundColor: 'blue', 
                    borderWidth: 1, 
                    pointBorderColor: 'blue',
                    responsive: true,
                    maintainAspectRatio: false,
                    data: this.kelembapan0
                },]
            }
            
        },

        fillDataHumidity2() {
          this.datacollection18 = {
                labels: this.time,
                
                datasets: [{
                    label: 'Sensor 2 Humidity (%)',
                    backgroundColor: 'rgba(0, 0, 255, 0.2)',
                    fill: false,
                    borderColor: 'lightblue', 
                    pointBackgroundColor: 'blue', 
                    borderWidth: 1, 
                    pointBorderColor: 'blue',
                    responsive: true,
                    maintainAspectRatio: false,
                    data: this.kelembapan1
                },]
            }
            
        },

        fillDataHumidity3() {
          this.datacollection19 = {
                labels: this.time,
                
                datasets: [{
                    label: 'Sensor 3 Humidity (%)',
                    backgroundColor: 'rgba(0, 0, 255, 0.2)',
                    fill: false,
                    borderColor: 'lightblue', 
                    pointBackgroundColor: 'blue', 
                    borderWidth: 1, 
                    pointBorderColor: 'blue',
                    responsive: true,
                    maintainAspectRatio: false,
                    data: this.kelembapan2
                },]
            }
            
        },

        fillDataHumidity4() {
          this.datacollection20 = {
                labels: this.time,
                
                datasets: [{
                    label: 'Sensor 4 Humidity (%)',
                    backgroundColor: 'rgba(0, 0, 255, 0.2)',
                    fill: false,
                    borderColor: 'lightblue', 
                    pointBackgroundColor: 'blue', 
                    borderWidth: 1, 
                    pointBorderColor: 'blue',
                    responsive: true,
                    maintainAspectRatio: false,
                    data: this.kelembapan3
                },]
            }
            
        },

        fillDataHumidity5() {
          this.datacollection21 = {
                labels: this.time,
                
                datasets: [{
                    label: 'Sensor 5 Humidity (%)',
                    backgroundColor: 'rgba(0, 0, 255, 0.2)',
                    fill: false,
                    borderColor: 'lightblue', 
                    pointBackgroundColor: 'blue', 
                    borderWidth: 1, 
                    pointBorderColor: 'blue',
                    responsive: true,
                    maintainAspectRatio: false,
                    data: this.kelembapan4
                },]
            }
            
        },

        fillDataHumidity6() {
          this.datacollection22 = {
                labels: this.time,
                
                datasets: [{
                    label: 'Sensor 6 Humidity (%)',
                    backgroundColor: 'rgba(0, 0, 255, 0.2)',
                    fill: false,
                    borderColor: 'lightblue', 
                    pointBackgroundColor: 'blue', 
                    borderWidth: 1, 
                    pointBorderColor: 'blue',
                    responsive: true,
                    maintainAspectRatio: false,
                    data: this.kelembapan5
                },]
            }
            
        },

        fillDataHumidity7() {
          this.datacollection23 = {
                labels: this.time,
                
                datasets: [{
                    label: 'Sensor 7 Humidity (%)',
                    backgroundColor: 'rgba(0, 0, 255, 0.2)',
                    fill: false,
                    borderColor: 'lightblue', 
                    pointBackgroundColor: 'blue', 
                    borderWidth: 1, 
                    pointBorderColor: 'blue',
                    responsive: true,
                    maintainAspectRatio: false,
                    data: this.kelembapan6
                },]
            }
            
        },

        fillDataHumidity8() {
          this.datacollection24 = {
                labels: this.time,
                
                datasets: [{
                    label: 'Sensor 8 Humidity (%)',
                    backgroundColor: 'rgba(0, 0, 255, 0.2)',
                    fill: false,
                    borderColor: 'lightblue', 
                    pointBackgroundColor: 'blue', 
                    borderWidth: 1, 
                    pointBorderColor: 'blue',
                    responsive: true,
                    maintainAspectRatio: false,
                    data: this.kelembapan7
                },]
            }
            
        },

        mySortCompare(a, b, key) {
            if (key === 'date') {
                let aDate = a.date
                let bDate = b.date
        
                if (aDate < bDate) {
                    return -1
                }
                else if (aDate > bDate) {
                    return 1
                }
                else {
                    return 0
                }

            } 
            else {
                return false
            }
        },

        timeInitialFrom() {
            let currentDate = new Date()
            this.dateFrom = currentDate
        },

        timeInitialTo() {
            let currentDate = new Date()
            this.dateTo = currentDate
        },

        getDataByDate() {
          this.allDataSensor = []
          let dateFrom = moment(this.formatDateFrom.toString()).format("L")
          let dateTo = moment(this.formatDateTo.toString()).format("L")

          let obj = {
              dateFrom: dateFrom,
              dateTo: dateTo
          }

          console.log(obj, 'obj')

          let config = {
                method: 'POST',
                headers: {'auth-tok': localStorage.getItem('token_monitor')},
                baseURL: `${this.url}/sensors/alldatabydate`,
                data: obj
            }
            axios(config)
            .then((response) => {
                this.allDataSensor = response.data.data
                return this.allDataSensor
                
            })
            .catch((error) => {
                console.log(error.message)
            })
        },

        getSensTemperature() {
                    let sens = this.allDataSensor
                    let filtersuhu0 = sens.filter(function (sen) {
                        return sen.sensor === "0";
                        }).map(function (sen) {
                            return sen.suhu;
                        })
                    let filtersuhu0time = sens.filter(function (sen) {
                        return sen.sensor === "0";
                        }).map(function (sen) {
                            return sen.time;
                        })
                    let temp = []
                    let time = []
                    for(let i=0; i<filtersuhu0.length; i++) {
                        let t = Number(filtersuhu0[i])
                        temp.push(t)
                    }
                    for(let i=0; i<filtersuhu0time.length; i++) {
                        let ti = filtersuhu0time[i]
                        time.push(ti)
                    }
                    this.suhu0 = temp
                    this.time = time
                    this.loaded = true
                    this.fillDataTemperature()

        },

        getSensTemperature2() {
                    let sens = this.allDataSensor
                    let filtersuhu1 = sens.filter(function (sen) {
                        return sen.sensor === "1";
                        }).map(function (sen) {
                            return sen.suhu;
                        })
                    let filtersuhu1time = sens.filter(function (sen) {
                        return sen.sensor === "1";
                        }).map(function (sen) {
                            return sen.time;
                        })
                    let temp = []
                    let time = []
                    for(let i=0; i<filtersuhu1.length; i++) {
                        let t = Number(filtersuhu1[i])
                        temp.push(t)
                    }
                    for(let i=0; i<filtersuhu1time.length; i++) {
                        let ti = filtersuhu1time[i]
                        time.push(ti)
                    }
                    this.suhu1 = temp
                    this.time = time
                    this.loaded = true
                    this.fillDataTemperature2()

        },

        getSensTemperature3() {
                    let sens = this.allDataSensor
                    let filtersuhu2 = sens.filter(function (sen) {
                        return sen.sensor === "2";
                        }).map(function (sen) {
                            return sen.suhu;
                        })
                    let filtersuhu2time = sens.filter(function (sen) {
                        return sen.sensor === "2";
                        }).map(function (sen) {
                            return sen.time;
                        })
                    let temp = []
                    let time = []
                    for(let i=0; i<filtersuhu2.length; i++) {
                        let t = Number(filtersuhu2[i])
                        temp.push(t)
                    }
                    for(let i=0; i<filtersuhu2time.length; i++) {
                        let ti = filtersuhu2time[i]
                        time.push(ti)
                    }
                    this.suhu2 = temp
                    this.time = time
                    this.loaded = true
                    this.fillDataTemperature3()

        },

        getSensTemperature4() {
                    let sens = this.allDataSensor
                    let filtersuhu3 = sens.filter(function (sen) {
                        return sen.sensor === "3";
                        }).map(function (sen) {
                            return sen.suhu;
                        })
                    let filtersuhu3time = sens.filter(function (sen) {
                        return sen.sensor === "3";
                        }).map(function (sen) {
                            return sen.time;
                        })
                    let temp = []
                    let time = []
                    for(let i=0; i<filtersuhu3.length; i++) {
                        let t = Number(filtersuhu3[i])
                        temp.push(t)
                    }
                    for(let i=0; i<filtersuhu3time.length; i++) {
                        let ti = filtersuhu3time[i]
                        time.push(ti)
                    }
                    this.suhu3 = temp
                    this.time = time
                    this.loaded = true
                    this.fillDataTemperature4()
        },

        getSensTemperature5() {
                    let sens = this.allDataSensor
                    let filtersuhu4 = sens.filter(function (sen) {
                        return sen.sensor === "4";
                        }).map(function (sen) {
                            return sen.suhu;
                        })
                    let filtersuhu4time = sens.filter(function (sen) {
                        return sen.sensor === "4";
                        }).map(function (sen) {
                            return sen.time;
                        })
                    let temp = []
                    let time = []
                    for(let i=0; i<filtersuhu4.length; i++) {
                        let t = Number(filtersuhu4[i])
                        temp.push(t)
                    }
                    for(let i=0; i<filtersuhu4time.length; i++) {
                        let ti = filtersuhu4time[i]
                        time.push(ti)
                    }
                    this.suhu4 = temp
                    this.time = time
                    this.loaded = true
                    this.fillDataTemperature5()
        },

        getSensTemperature6() {
                    let sens = this.allDataSensor
                    let filtersuhu5 = sens.filter(function (sen) {
                        return sen.sensor === "5";
                        }).map(function (sen) {
                            return sen.suhu;
                        })
                    let filtersuhu5time = sens.filter(function (sen) {
                        return sen.sensor === "5";
                        }).map(function (sen) {
                            return sen.time;
                        })
                    let temp = []
                    let time = []
                    for(let i=0; i<filtersuhu5.length; i++) {
                        let t = Number(filtersuhu5[i])
                        temp.push(t)
                    }
                    for(let i=0; i<filtersuhu5time.length; i++) {
                        let ti = filtersuhu5time[i]
                        time.push(ti)
                    }
                    this.suhu5 = temp
                    this.time = time
                    this.loaded = true
                    this.fillDataTemperature6()
        },

        getSensTemperature7() {
                    let sens = this.allDataSensor
                    let filtersuhu6 = sens.filter(function (sen) {
                        return sen.sensor === "6";
                        }).map(function (sen) {
                            return sen.suhu;
                        })
                    let filtersuhu6time = sens.filter(function (sen) {
                        return sen.sensor === "6";
                        }).map(function (sen) {
                            return sen.time;
                        })
                    let temp = []
                    let time = []
                    for(let i=0; i<filtersuhu6.length; i++) {
                        let t = Number(filtersuhu6[i])
                        temp.push(t)
                    }
                    for(let i=0; i<filtersuhu6time.length; i++) {
                        let ti = filtersuhu6time[i]
                        time.push(ti)
                    }
                    this.suhu6 = temp
                    this.time = time
                    this.loaded = true
                    this.fillDataTemperature7()

        },

        getSensTemperature8() {
                    let sens = this.allDataSensor
                    let filtersuhu7 = sens.filter(function (sen) {
                        return sen.sensor === "7";
                        }).map(function (sen) {
                            return sen.suhu;
                        })
                    let filtersuhu7time = sens.filter(function (sen) {
                        return sen.sensor === "7";
                        }).map(function (sen) {
                            return sen.time;
                        })
                    let temp = []
                    let time = []
                    for(let i=0; i<filtersuhu7.length; i++) {
                        let t = Number(filtersuhu7[i])
                        temp.push(t)
                    }
                    for(let i=0; i<filtersuhu7time.length; i++) {
                        let ti = filtersuhu7time[i]
                        time.push(ti)
                    }
                    this.suhu7 = temp
                    this.time = time
                    this.loaded = true
                    this.fillDataTemperature8()

        },

        getSensPressure() {
                    let sens = this.allDataSensor
                    let filtertekanan0 = sens.filter(function (sen) {
                        return sen.sensor === "0";
                        }).map(function (sen) {
                            return sen.tekanan;
                        })
                    let filtertekanan0time = sens.filter(function (sen) {
                        return sen.sensor === "0";
                        }).map(function (sen) {
                            return sen.time;
                        })
                    let press = []
                    let time = []
                    for(let i=0; i<filtertekanan0.length; i++) {
                        let p = Number(filtertekanan0[i])
                        press.push(p)
                    }
                    for(let i=0; i<filtertekanan0time.length; i++) {
                        let ti = filtertekanan0time[i]
                        time.push(ti)
                    }
                    this.tekanan0 = press
                    this.time = time
                    this.loaded = true
                    this.fillDataPressure()
        },

        getSensPressure2() {
                    let sens = this.allDataSensor
                    let filtertekanan1 = sens.filter(function (sen) {
                        return sen.sensor === "1";
                        }).map(function (sen) {
                            return sen.tekanan;
                        })
                    let filtertekanan1time = sens.filter(function (sen) {
                        return sen.sensor === "1";
                        }).map(function (sen) {
                            return sen.time;
                        })
                    let press = []
                    let time = []
                    for(let i=0; i<filtertekanan1.length; i++) {
                        let p = Number(filtertekanan1[i])
                        press.push(p)
                    }
                    for(let i=0; i<filtertekanan1time.length; i++) {
                        let ti = filtertekanan1time[i]
                        time.push(ti)
                    }
                    this.tekanan1 = press
                    this.time = time
                    this.loaded = true
                    this.fillDataPressure2()
        },

        getSensPressure3() {
                    let sens = this.allDataSensor
                    let filtertekanan2 = sens.filter(function (sen) {
                        return sen.sensor === "2";
                        }).map(function (sen) {
                            return sen.tekanan;
                        })
                    let filtertekanan2time = sens.filter(function (sen) {
                        return sen.sensor === "2";
                        }).map(function (sen) {
                            return sen.time;
                        })
                    let press = []
                    let time = []
                    for(let i=0; i<filtertekanan2.length; i++) {
                        let p = Number(filtertekanan2[i])
                        press.push(p)
                    }
                    for(let i=0; i<filtertekanan2time.length; i++) {
                        let ti = filtertekanan2time[i]
                        time.push(ti)
                    }
                    this.tekanan2 = press
                    this.time = time
                    this.loaded = true
                    this.fillDataPressure3()
        },

        getSensPressure4() {
                    let sens = this.allDataSensor
                    let filtertekanan3 = sens.filter(function (sen) {
                        return sen.sensor === "3";
                        }).map(function (sen) {
                            return sen.tekanan;
                        })
                    let filtertekanan3time = sens.filter(function (sen) {
                        return sen.sensor === "3";
                        }).map(function (sen) {
                            return sen.time;
                        })
                    let press = []
                    let time = []
                    for(let i=0; i<filtertekanan3.length; i++) {
                        let p = Number(filtertekanan3[i])
                        press.push(p)
                    }
                    for(let i=0; i<filtertekanan3time.length; i++) {
                        let ti = filtertekanan3time[i]
                        time.push(ti)
                    }
                    this.tekanan3 = press
                    this.time = time
                    this.loaded = true
                    this.fillDataPressure4()
        },

        getSensPressure5() {
                    let sens = this.allDataSensor
                    let filtertekanan4 = sens.filter(function (sen) {
                        return sen.sensor === "4";
                        }).map(function (sen) {
                            return sen.tekanan;
                        })
                    let filtertekanan4time = sens.filter(function (sen) {
                        return sen.sensor === "4";
                        }).map(function (sen) {
                            return sen.time;
                        })
                    let press = []
                    let time = []
                    for(let i=0; i<filtertekanan4.length; i++) {
                        let p = Number(filtertekanan4[i])
                        press.push(p)
                    }
                    for(let i=0; i<filtertekanan4time.length; i++) {
                        let ti = filtertekanan4time[i]
                        time.push(ti)
                    }
                    this.tekanan4 = press
                    this.time = time
                    this.loaded = true
                    this.fillDataPressure5()
        },

        getSensPressure6() {
                    let sens = this.allDataSensor
                    let filtertekanan5 = sens.filter(function (sen) {
                        return sen.sensor === "5";
                        }).map(function (sen) {
                            return sen.tekanan;
                        })
                    let filtertekanan5time = sens.filter(function (sen) {
                        return sen.sensor === "5";
                        }).map(function (sen) {
                            return sen.time;
                        })
                    let press = []
                    let time = []
                    for(let i=0; i<filtertekanan5.length; i++) {
                        let p = Number(filtertekanan5[i])
                        press.push(p)
                    }
                    for(let i=0; i<filtertekanan5time.length; i++) {
                        let ti = filtertekanan5time[i]
                        time.push(ti)
                    }
                    this.tekanan5 = press
                    this.time = time
                    this.loaded = true
                    this.fillDataPressure6()
        },

        getSensPressure7() {
                    let sens = this.allDataSensor
                    let filtertekanan6 = sens.filter(function (sen) {
                        return sen.sensor === "6";
                        }).map(function (sen) {
                            return sen.tekanan;
                        })
                    let filtertekanan6time = sens.filter(function (sen) {
                        return sen.sensor === "6";
                        }).map(function (sen) {
                            return sen.time;
                        })
                    let press = []
                    let time = []
                    for(let i=0; i<filtertekanan6.length; i++) {
                        let p = Number(filtertekanan6[i])
                        press.push(p)
                    }
                    for(let i=0; i<filtertekanan6time.length; i++) {
                        let ti = filtertekanan6time[i]
                        time.push(ti)
                    }
                    this.tekanan6 = press
                    this.time = time
                    this.loaded = true
                    this.fillDataPressure7()
        },

        getSensPressure8() {
                    let sens = this.allDataSensor
                    let filtertekanan7 = sens.filter(function (sen) {
                        return sen.sensor === "7";
                        }).map(function (sen) {
                            return sen.tekanan;
                        })
                    let filtertekanan7time = sens.filter(function (sen) {
                        return sen.sensor === "7";
                        }).map(function (sen) {
                            return sen.time;
                        })
                    let press = []
                    let time = []
                    for(let i=0; i<filtertekanan7.length; i++) {
                        let p = Number(filtertekanan7[i])
                        press.push(p)
                    }
                    for(let i=0; i<filtertekanan7time.length; i++) {
                        let ti = filtertekanan7time[i]
                        time.push(ti)
                    }
                    this.tekanan7 = press
                    this.time = time
                    this.loaded = true
                    this.fillDataPressure8()
        },

        getSensHumidity() {
                    let sens = this.allDataSensor
                    let filterkelembapan0 = sens.filter(function (sen) {
                        return sen.sensor === "0";
                        }).map(function (sen) {
                            return sen.kelembapan;
                        })
                    let filterkelembapan0time = sens.filter(function (sen) {
                        return sen.sensor === "0";
                        }).map(function (sen) {
                            return sen.time;
                        })
                    let hum = []
                    let time = []
                    for(let i=0; i<filterkelembapan0.length; i++) {
                        let h = Number(filterkelembapan0[i])
                        hum.push(h)
                    }
                    for(let i=0; i<filterkelembapan0time.length; i++) {
                        let ti = filterkelembapan0time[i]
                        time.push(ti)
                    }
                    this.kelembapan0 = hum
                    this.time = time
                    this.loaded = true
                    this.fillDataHumidity()
        },

        getSensHumidity2() {
                    let sens = this.allDataSensor
                    let filterkelembapan1 = sens.filter(function (sen) {
                        return sen.sensor === "1";
                        }).map(function (sen) {
                            return sen.kelembapan;
                        })
                    let filterkelembapan1time = sens.filter(function (sen) {
                        return sen.sensor === "1";
                        }).map(function (sen) {
                            return sen.time;
                        })
                    let hum = []
                    let time = []
                    for(let i=0; i<filterkelembapan1.length; i++) {
                        let h = Number(filterkelembapan1[i])
                        hum.push(h)
                    }
                    for(let i=0; i<filterkelembapan1time.length; i++) {
                        let ti = filterkelembapan1time[i]
                        time.push(ti)
                    }
                    this.kelembapan1 = hum
                    this.time = time
                    this.loaded = true
                    this.fillDataHumidity2()
        },

        getSensHumidity3() {
                    let sens = this.allDataSensor
                    let filterkelembapan2 = sens.filter(function (sen) {
                        return sen.sensor === "2";
                        }).map(function (sen) {
                            return sen.kelembapan;
                        })
                    let filterkelembapan2time = sens.filter(function (sen) {
                        return sen.sensor === "2";
                        }).map(function (sen) {
                            return sen.time;
                        })
                    let hum = []
                    let time = []
                    for(let i=0; i<filterkelembapan2.length; i++) {
                        let h = Number(filterkelembapan2[i])
                        hum.push(h)
                    }
                    for(let i=0; i<filterkelembapan2time.length; i++) {
                        let ti = filterkelembapan2time[i]
                        time.push(ti)
                    }
                    this.kelembapan2 = hum
                    this.time = time
                    this.loaded = true
                    this.fillDataHumidity3()
        },

        getSensHumidity4() {
                    let sens = this.allDataSensor
                    let filterkelembapan3 = sens.filter(function (sen) {
                        return sen.sensor === "3";
                        }).map(function (sen) {
                            return sen.kelembapan;
                        })
                    let filterkelembapan3time = sens.filter(function (sen) {
                        return sen.sensor === "3";
                        }).map(function (sen) {
                            return sen.time;
                        })
                    let hum = []
                    let time = []
                    for(let i=0; i<filterkelembapan3.length; i++) {
                        let h = Number(filterkelembapan3[i])
                        hum.push(h)
                    }
                    for(let i=0; i<filterkelembapan3time.length; i++) {
                        let ti = filterkelembapan3time[i]
                        time.push(ti)
                    }
                    this.kelembapan3 = hum
                    this.time = time
                    this.loaded = true
                    this.fillDataHumidity4()

        },

        getSensHumidity5() {
                    let sens = this.allDataSensor
                    let filterkelembapan4 = sens.filter(function (sen) {
                        return sen.sensor === "4";
                        }).map(function (sen) {
                            return sen.kelembapan;
                        })
                    let filterkelembapan4time = sens.filter(function (sen) {
                        return sen.sensor === "4";
                        }).map(function (sen) {
                            return sen.time;
                        })
                    let hum = []
                    let time = []
                    for(let i=0; i<filterkelembapan4.length; i++) {
                        let h = Number(filterkelembapan4[i])
                        hum.push(h)
                    }
                    for(let i=0; i<filterkelembapan4time.length; i++) {
                        let ti = filterkelembapan4time[i]
                        time.push(ti)
                    }
                    this.kelembapan4 = hum
                    this.time = time
                    this.loaded = true
                    this.fillDataHumidity5()
        },

        getSensHumidity6() {
                    let sens = this.allDataSensor
                    let filterkelembapan5 = sens.filter(function (sen) {
                        return sen.sensor === "5";
                        }).map(function (sen) {
                            return sen.kelembapan;
                        })
                    let filterkelembapan5time = sens.filter(function (sen) {
                        return sen.sensor === "5";
                        }).map(function (sen) {
                            return sen.time;
                        })
                    let hum = []
                    let time = []
                    for(let i=0; i<filterkelembapan5.length; i++) {
                        let h = Number(filterkelembapan5[i])
                        hum.push(h)
                    }
                    for(let i=0; i<filterkelembapan5time.length; i++) {
                        let ti = filterkelembapan5time[i]
                        time.push(ti)
                    }
                    this.kelembapan5 = hum
                    this.time = time
                    this.loaded = true
                    this.fillDataHumidity6()
        },

        getSensHumidity7() {
                    let sens = this.allDataSensor
                    let filterkelembapan7 = sens.filter(function (sen) {
                        return sen.sensor === "7";
                        }).map(function (sen) {
                            return sen.kelembapan;
                        })
                    let filterkelembapan7time = sens.filter(function (sen) {
                        return sen.sensor === "7";
                        }).map(function (sen) {
                            return sen.time;
                        })
                    let hum = []
                    let time = []
                    for(let i=0; i<filterkelembapan7.length; i++) {
                        let h = Number(filterkelembapan7[i])
                        hum.push(h)
                    }
                    for(let i=0; i<filterkelembapan7time.length; i++) {
                        let ti = filterkelembapan7time[i]
                        time.push(ti)
                    }
                    this.kelembapan6 = hum
                    this.time = time
                    this.loaded = true
                    this.fillDataHumidity7()
        },

        getSensHumidity8() {
                    let sens = this.allDataSensor
                    let filterkelembapan7 = sens.filter(function (sen) {
                        return sen.sensor === "7";
                        }).map(function (sen) {
                            return sen.kelembapan;
                        })
                    let filterkelembapan7time = sens.filter(function (sen) {
                        return sen.sensor === "7";
                        }).map(function (sen) {
                            return sen.time;
                        })
                    let hum = []
                    let time = []
                    for(let i=0; i<filterkelembapan7.length; i++) {
                        let h = Number(filterkelembapan7[i])
                        hum.push(h)
                    }
                    for(let i=0; i<filterkelembapan7time.length; i++) {
                        let ti = filterkelembapan7time[i]
                        time.push(ti)
                    }
                    this.kelembapan7 = hum
                    this.time = time
                    this.loaded = true
                    this.fillDataHumidity8()
        },

  },
  created() {
      if (!localStorage.getItem("token_monitor")) {
          this.$router.push('/')
      }
      else {
            this.getUserInfo()
            this.getData()
            this.timeInitialFrom()
            this.timeInitialTo()
            this.getDataByDate()
            this.fillDataTemperature()
            this.fillDataTemperature2()
            this.fillDataTemperature3()
            this.fillDataTemperature4()
            this.fillDataTemperature5()
            this.fillDataTemperature6()
            this.fillDataTemperature7()
            this.fillDataTemperature8()
            this.fillDataPressure()
            this.fillDataPressure2()
            this.fillDataPressure3()
            this.fillDataPressure4()
            this.fillDataPressure5()
            this.fillDataPressure6()
            this.fillDataPressure7()
            this.fillDataPressure8()
            this.fillDataHumidity()
            this.fillDataHumidity2()
            this.fillDataHumidity3()
            this.fillDataHumidity4()
            this.fillDataHumidity5()
            this.fillDataHumidity6()
            this.fillDataHumidity7()
            this.fillDataHumidity8()
            this.loaded = false
            this.getSensTemperature()
            this.getSensTemperature2()
            this.getSensTemperature3()
            this.getSensTemperature4()
            this.getSensTemperature5()
            this.getSensTemperature6()
            this.getSensTemperature7()
            this.getSensTemperature8()
            this.getSensPressure()
            this.getSensPressure2()
            this.getSensPressure3()
            this.getSensPressure4()
            this.getSensPressure5()
            this.getSensPressure6()
            this.getSensPressure7()
            this.getSensPressure8()
            this.getSensHumidity()
            this.getSensHumidity2()
            this.getSensHumidity3()
            this.getSensHumidity4()
            this.getSensHumidity5()
            this.getSensHumidity6()
            this.getSensHumidity7()
            this.getSensHumidity8()        
      }
  },
  mounted() {
      if (localStorage.getItem('reloaded')) {
          localStorage.removeItem('reloaded');
      }
      else {
          localStorage.setItem('reloaded', '1');
          location.reload();
       }
       setInterval( () => {
            this.getData()
            this.fillDataTemperature()
            this.fillDataTemperature2()
            this.fillDataTemperature3()
            this.fillDataTemperature4()
            this.fillDataTemperature5()
            this.fillDataTemperature6()
            this.fillDataTemperature7()
            this.fillDataTemperature8()
            this.fillDataPressure()
            this.fillDataPressure2()
            this.fillDataPressure3()
            this.fillDataPressure4()
            this.fillDataPressure5()
            this.fillDataPressure6()
            this.fillDataPressure7()
            this.fillDataPressure8()
            this.fillDataHumidity()
            this.fillDataHumidity2()
            this.fillDataHumidity3()
            this.fillDataHumidity4()
            this.fillDataHumidity5()
            this.fillDataHumidity6()
            this.fillDataHumidity7()
            this.fillDataHumidity8()
            this.getSensTemperature()
            this.getSensTemperature2()
            this.getSensTemperature3()
            this.getSensTemperature4()
            this.getSensTemperature5()
            this.getSensTemperature6()
            this.getSensTemperature7()
            this.getSensTemperature8()
            this.getSensPressure()
            this.getSensPressure2()
            this.getSensPressure3()
            this.getSensPressure4()
            this.getSensPressure5()
            this.getSensPressure6()
            this.getSensPressure7()
            this.getSensPressure8()
            this.getSensHumidity()
            this.getSensHumidity2()
            this.getSensHumidity3()
            this.getSensHumidity4()
            this.getSensHumidity5()
            this.getSensHumidity6()
            this.getSensHumidity7()
            this.getSensHumidity8()
        }, 2500)
  },

  computed: {
        rows() {
            return this.allHistory.length
        },

        lastItem() {
            return this.allHistory.slice(-1)[0]
        },

        formatDateFrom() {
            let fromDate = this.dateFrom
            fromDate.setHours(0,0,0,0)
            return fromDate
        },

        formatDateTo() {
            let toDate = this.dateTo
            toDate.setHours(24,0,0,0)
            return toDate
        }
    }
}
</script>