<template>
  <div class="home bg-gradient-primary">
    <div class="container">

        <!-- Outer Row -->
        <div class="row justify-content-center align-items-center" style="min-height:100vh;">

            <div class="col-xl-10 col-lg-12 col-md-9">

                <div class="card o-hidden border-0 shadow-lg my-5">
                    <div class="card-body p-0">
                        <!-- Nested Row within Card Body -->
                        <div class="row">
                            <div class="col-lg-6 d-none d-lg-block bg-login-image"></div>
                            <div class="col-lg-6">
                                <div class="p-5">
                                    <div class="text-center">
                                        <h1 class="h4 text-gray-900 mb-4"></h1>
                                    </div>
                                    <form class="user">
                                        <div class="form-group">
                                            <input v-model="username" type="username" class="form-control form-control-user"
                                                id="exampleInputUsername" aria-describedby="usernameHelp"
                                                placeholder="Enter Username...">
                                        </div>
                                        <div class="form-group">
                                            <input v-model="email" type="email" class="form-control form-control-user"
                                                id="exampleInputEmail" aria-describedby="emailHelp"
                                                placeholder="Enter Email...">
                                        </div>
                                        <div class="form-group">
                                            <input v-model="password" type="password" class="form-control form-control-user"
                                                id="exampleInputPassword" placeholder="Password">
                                        </div>
                                        <button @click.prevent="signUp" class="btn btn-primary btn-user btn-block">
                                            Sign Up
                                        </button>
                                        <button @click.prevent="back" class="btn btn-danger btn-user btn-block">
                                            Back
                                        </button>
                                    </form>
                                </div>
                                <div style="text-align: center;">
                                    <p style="font-size:12px">Copyright &copy; 2022 Nanosense Instrument Indonesia. All rights reserved.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>

    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import Swal from 'sweetalert2'

export default {
  name: 'Signup',
  props: ['url'],
  data: () => {
      return {
          username: "",
          email: "",
          password: "",
        }
    },
  methods: {
      signUp() {
          let obj = {
            // Ini harus sama kaya backend nya   
              name: this.username,
              email: this.email,
              password: this.password
          }
        //   console.log(obj)
          axios.post(`${this.url}/users/create`, obj)
            .then((response) => {
                console.log(response.data)
                Swal.fire('Thanks For register', '', 'success')
                this.$router.push("/")
            })
            .catch((error) => {
                Swal.fire('Something went wrong!', '', 'error')
                console.log(error.message)
            })
        },
      back() {
          this.$router.push("/")
      }
    }
}
</script>
